import { useRef } from 'react';
import PortfolioItem from './PortfolioItem';
import { getTranslation } from '../utils';
import '../assets/styles/Portfolio.scss';

const Portfolio = () => {
  const translation = getTranslation();
  const discordBotsData = useRef([
    {
      link: 'https://discord.com/api/',
      title: 'GTA5RP | Richman',
      description: translation.key === 'ru' ? 'Бот для помощи администрации GTA5RP и игроков' : 'Bot for help GTA5RP administration and players',
      status: 'off'
    },
    {
      title: 'Andreevich Bot',
      description: translation.key === 'ru' ? 'Личный бот для облегчения жизни и работы' : 'Personal bot to make life and work easier',
      status: 'private'
    },
    {
      title: translation.key === 'ru' ? 'Мини диджей' : 'Mini DJ',
      description: translation.key === 'ru' ? 'Музыкальный бот для включения желаемой музыки' : 'Music bot to turn on the desired music',
      status: 'off'
    },
    {
      title: translation.key === 'ru' ? 'Заведующий комнатами' : 'Room Manager',
      description: translation.key === 'ru' ? 'Бот который создает приватные комнаты и предоставляет управление ими' : 'Bot that creates private rooms and provides management of them',
      status: 'private'
    },
    {
      title: translation.key === 'ru' ? 'Счетчик' : 'Counter',
      description: translation.key === 'ru' ? 'Счетчик онлайна на серверах, подведение статистики' : 'Online counter on servers, summarizing statistics',
      status: 'off'
    },
  ]).current;

  const webData = useRef([
    {
      title: 'GTA5RP | Richman Dashboard panel',
      description: 'Full Stack . NextJS + React JS'
    },
    {
      title: 'Bambik',
      description: 'Full Stack . Next TS + React TS + Tailwind CSS'
    },
    {
      link: 'https://andreevich404.ru',
      title: 'andreevich404',
      description: 'Frontend . React'
    },
    {
      title: 'Pindie',
      description: 'Учебный проект для Яндекс практикума. Full Stack . React + Express JS + Mongo DB'
    },
  ]).current;

  const telegramData = useRef([
    {
      link: 'https://t.me/',
      title: 'GTA5RP Richman',
      description: translation.key === 'ru' ? 'Бот для администрации сервера Richman проекта GTA5RP' : 'Bot for the administration of the Richman server of the GTA5RP project',
      status: 'off'
    },
    {
      title: 'Giveway Bot',
      description: translation.key === 'ru' ? 'Бот с web_app интерфейсом для конкурсов' : 'Bot with web_app interface for contests',
      status: 'private'
    },
    {
      title: 'Shirker',
      description: translation.key === 'ru' ? 'Бот для учебной группы, упрощает жизнь студентов и старост' : 'Bot for the study group, simplifies the lives of students and elders',
      status: 'private'
    }
  ]).current;

  const mobileData = useRef([
    {
      title: 'Learn JS',
      description: 'Учебник для изучения языка JavaScript. Android . React Native',
    }
  ]).current;

  const desktopData = useRef([
    {
      title: 'CH (Cheat Hunter)',
      description: translation.key === 'ru' ? 'Программа для проверок компьютера на использование читов' : 'Program for checking your computer for the use of cheats',
    }
  ]).current;

  const othersData = useRef([
    {
      title: 'GTA5RP | Finder',
      description: translation.key === 'ru' ? 'Расширение для браузера, ищет нарушителей по логам' : 'Browser extension, searches for intruders by logs',
      status: 'private'
    },
    {
      title: 'Watermarker',
      description: translation.key === 'ru' ? 'Приложение для встраивания цифрового знака в изображение . Python' : 'Application for embedding a digital sign in an image . Python',
      status: 'off'
    },
  ]).current;

  return (
    <section id="portfolio">
      <h2>{translation.portfolio.title}</h2>
      <div className="portfolio-container">
        <h5>Discord</h5>
        <div className="portfolio-container--items">
          {discordBotsData.map((value, index) => (
            <PortfolioItem key={index} link={value.link} title={value.title} description={value.description} status={value.status} />
          ))}
        </div>
      </div>
      <div className="portfolio-container">
        <h5>Telegram</h5>
        <div className="portfolio-container--items">
          {telegramData.map((value, index) => (
            <PortfolioItem key={index} link={value.link} title={value.title} description={value.description} status={value.status} />
          ))}
        </div>
      </div>
      <div className="portfolio-container">
        <h5>DESKTOP</h5>
        <div className="portfolio-container--items">
          {desktopData.map((value, index) => (
            <PortfolioItem key={index} title={value.title} description={value.description} />
          ))}
        </div>
      </div>
      <div className="portfolio-container">
        <h5>MOBILE</h5>
        <div className="portfolio-container--items">
          {mobileData.map((value, index) => (
            <PortfolioItem key={index} title={value.title} description={value.description} />
          ))}
        </div>
      </div>
      <div className="portfolio-container">
        <h5>WEB</h5>
        <div className="portfolio-container--items">
          {webData.map((value, index) => (
            <PortfolioItem key={index} link={value.link} title={value.title} description={value.description} />
          ))}
        </div>
      </div>
      <div className="portfolio-container">
        <h5>Others</h5>
        <div className="portfolio-container--items">
          {othersData.map((value, index) => (
            <PortfolioItem key={index} title={value.title} description={value.description} status={value.status} />
          ))}
        </div>
      </div>
      <div className="my-contacts">
        <div className="my-contacts--title">{translation.portfolio.thatsMe}</div>
        <div className="my-contacts--links">
          <a href="https://vk.com/idtolkachev" target="_blank" rel="noreferrer">
            VK
          </a>
          <a href="https://t.me/minot42" target="_blank" rel="noreferrer">
            TELEGRAM
          </a>
          <a href="https://steamcommunity.com/id/spookeen" target="_blank" rel="noreferrer">
            STEAM
          </a>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
